import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SidebarService } from '../shared/services/sidebar.service';
// import { mainContentAnimation } from './animations';
import { CommonModule } from '@angular/common';
import { ExtendedModule } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from 'rxjs';
import { UtilityService } from '../core/services/utility.service';
import { mainContentAnimation } from '../shared/animations';
import { HeaderComponent } from '../shared/components/header/header.component';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { DiagnosisService } from '../shared/services/doctor-service/diagnosis.service';
import { ToggleService } from '../shared/components/header/toggle.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [mainContentAnimation()],
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    SidebarComponent,
    HeaderComponent,

    ExtendedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutComponent implements OnInit {
  public myData = new BehaviorSubject(null);
  public currentRole = new BehaviorSubject(null);
  public isAcess = new BehaviorSubject(false);
  displayError: boolean = false;

  sidebarState: string;
  showHead: boolean = false;
  userInfo: any = '';
  isLogin: boolean;
  viewedPage: any;
  patientName: any;
  careplanNumber: any = { currentCarePlan: 0, totalCarePlan: 0 };
  appointmentId: any;
  patientId: any;
  vitalDetail: any;
  currentCarePlan: any;
  totalCarePlan: any = 0;

  // Toggle Service
  isToggled = false;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    public dialogRef: MatDialog,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private appointmentService: DiagnosisService,
    public toggleService: ToggleService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });

    this.utilityService.isAcess
      .pipe(distinctUntilChanged())
      .subscribe((isAccess: boolean) => {
        this.userInfo = UtilityService.getUser();
        this.displayError = isAccess;
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: any) => {
        this.viewedPage = event.title;
        var patientId = UtilityService.getLocalStorage('patientId');
        var consultationId = UtilityService.getLocalStorage('consultationId');
        // var consultationId = JSON.parse(UtilityService.getLocalStorage("consultationDetails") || '')
        if (UtilityService.getLocalStorage('consultationDetails') != null) {
          var careplanId = JSON.parse(
            UtilityService.getLocalStorage('consultationDetails') || ''
          ).careplanId;
        }
        if (
          this.viewedPage == 'Vitals' ||
          this.viewedPage == 'Diagnosis' ||
          this.viewedPage == 'Patient Prescription'
        ) {
          if (careplanId != null) {
            this.fetchAppointmentDetail(consultationId, patientId);
          }
          // this.currentCarePlan = consultationId.currentConsultation,
          // this.totalCarePlan = consultationId.totalConsultation
          // var consultationId = JSON.parse(UtilityService.getLocalStorage("consultationDetails") || '')
          // this.currentCarePlan = consultationId.currentConsultation,
          // this.totalCarePlan = consultationId.totalConsultation
          this.patientName = UtilityService.getLocalStorage('patientName');
        }
      });

    this.reset();
  }

  // Dark Mode
  toggleTheme() {
    this.toggleService.toggleTheme();
  }

  // Settings Button Toggle
  toggle() {
    this.toggleService.toggle();
  }

  idleState = 'Not started.';
  timedOut = false;

  reset() {
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  logout() {
    this.dialogRef.closeAll();
    UtilityService.clearLocalStorage();
    window.location.href = 'http://localhost:4200/dashboard';
  }

  ngOnInit() {
    this.patientName = UtilityService.getLocalStorage('patientName');
    var patientId = UtilityService.getLocalStorage('patientId');
    var consultationId = UtilityService.getLocalStorage('consultationId');
    // var consultationId = JSON.parse(UtilityService.getLocalStorage("consultationDetails") || '')
    // this.currentCarePlan = consultationId.currentConsultation,
    // this.totalCarePlan = consultationId.totalConsultation
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.patientName = UtilityService.getLocalStorage('patientName');
        if (
          this.viewedPage == 'Vitals' ||
          this.viewedPage == 'Diagnosis' ||
          this.viewedPage == 'Patient Prescription'
        ) {
          // this.fetchAppointmentDetail(consultationId, patientId)
        }
      }
    });
    this.sidebarService.sidebarStateObservable$.subscribe(
      (newState: string) => {
        this.sidebarState = newState;
      }
    );
  }
  redirect() {
    this.router.navigate(['/dashboard/doctor']);
  }

  fetchAppointmentDetail(appointmentId: any, patientId: any) {
    this.appointmentService
      .viewConsultationDetails(patientId, appointmentId)
      .subscribe((response) => {

        (this.currentCarePlan = response.data.currentConsultation),
          (this.totalCarePlan = response.data.totalConsultation);
      });
  }
}
