<div fxLayout="column" style="height: 100vh" fxLayoutAlign="center center">
  <div>
    <img
      src="./assets/images/ThriveHub.svg"
      alt="ThriveHub Logo"
      style="height: 100px"
    />
  </div>
  <div>
    <p>Thank you.</p>
  </div>
</div>
