import { inject, Injectable } from '@angular/core';
import {  CanActivateFn, Router } from '@angular/router';
import { UtilityService } from '../services/utility.service';

export const opdGuard: CanActivateFn = (route, state) => {
  return inject(AccessTokenGuard).canActivate();
};

@Injectable({
  providedIn: 'root',
})
export class AccessTokenGuard{
  constructor(private router: Router) {}

  canActivate(): boolean {
    const accessToken = UtilityService.getAccessToken();
    if (accessToken) {
      return true; // Allow navigation
    } else {
      this.router.navigate(['/auth/doctor/login']); // Redirect to login if no accessToken
      return false; // Block navigation
    }
  }
}
