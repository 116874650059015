import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';


@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {


    const notifier = this.injector.get(NotificationService);
    const router = this.injector.get(Router);
    const api = this.injector.get(ApiService);
    let message;
    if (error instanceof HttpErrorResponse) {

    console.error(error);
  }
  }
  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ?
           error.error.message ? error.error.message : error.message :
           'No Internet Connection';
  }
}
