import { CanActivateFn, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { PermissionService } from 'src/app/shared/services/permission.service';

export const settingGuard: CanActivateFn = (route, state) => {
  return inject(SettingGuard).canActivate();
};

@Injectable({
  providedIn: 'root',
})
export class SettingGuard {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (
      this.permissionService.hasPermission('SETTING_MODULE') 
    ) {
      return true; // Block access to the setting route
    }
    // Redirect to the dashboard page if the user is not logged in or does not have the required permission
    this.router.navigate(['/dashboard/doctor'], { replaceUrl: true });
    return false;
  }
}
