import {  Component, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientDashboardService } from 'src/app/shared/services/patient-service/patient-dashboard.service';

@Component({
    selector: 'app-p1patient-post-registration',
    templateUrl: './p1patient-post-registration.component.html',
    styleUrls: ['./p1patient-post-registration.component.scss'],
    standalone: true,
    imports: [ ExtendedModule, FlexModule, FormsModule, ReactiveFormsModule, MatButton]
})


export class p1PatientPostRegistrationComponent implements OnInit {

  patientId: any;
  phoneNumber: any;
  doctorId: any;
  doctorName: any;
  status: any;
  organizationId:any;

  constructor(
    private patientDashboardService: PatientDashboardService,
    private route: ActivatedRoute,
    private router: Router) {
      this.patientId = this.route.snapshot.queryParams['patientId'];
      this.doctorId = this.route.snapshot.queryParams['doctorId'];
      this.doctorName = this.route.snapshot.queryParams['doctorName'];
      this.status = this.route.snapshot.queryParams['s'];
      this.organizationId = this.route.snapshot.queryParams['organizationId'];

  }

  ngOnInit(): void {


  }

  accessPermission(status:any){
    // const accessData = {
    //   DoctorId: this.doctorId,
    //   PatientId: this.patientId,
    //   RequestStatus: status
    // }
    this.patientDashboardService.permissionforPatientOrg(this.patientId, this.doctorId, status,this.organizationId).subscribe(
      (data1) => {
        if(data1.data){
          this.router.navigateByUrl('/patient-thank-you')
        }
      })
  }

}
