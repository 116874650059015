export const config = {
  partialUrls: {
    doctorLogin: '/api/Login/doctorlogin',
    staffLogin: '/api/login/StaffLogin',
    validateStaff: '/api/login/ValidateUser',
    ListOpdConsultation: '/RequestConsultation/ListOpdConsultation',
    ipdListing: '/RequestConsultation/ListIpdConsultation',
    searchPatient: '/api/Patient/ListPatient',
    createPatient: '/api/Registration/CreatePatient',
    getPatientInfo: '/api/Patient/PatientDetails',
    getPatientVisit: '/api/Patient/GetPatientVisit',
    createPatientVital: '/api/Analysis/AddVitals',
    viewPatientVital: '/api/Analysis/ViewVitals',
    addStaffVisitAttendance: '/api/Organization/AddStaffPatientAttendance',
    createOrgCarePlan: '/RequestConsultation/AddOrganizationCarePlan',
    viewOrgCarePlan: '/RequestConsultation/ViewOrganizationCarePlan',
    viewDiagnosisDetails: '/api/Diagnosis/ListDiagnosisOpen',
    viewInstructions: '/api/Diagnosis/ViewInstruction',
    updatePatientVital: '/api/Analysis/UpdateVitals',
    healthDetails: '/api/Patient/AddHealthDetails',
    AddAppointment: '/RequestConsultation/AddConsultation',
    ViewAppointment: '/RequestConsultation/ViewEncounters',
    updateAppointmentStatus: '/RequestConsultation/UpdateConsultation',
    patientregistration: '/api/Registration/CreatePatient',
    DoctorRegistration: '/api/Registration/CreateDoctor',
    OrganizationRegistration: '/api/registration/createorganization',
    patientLogin: '/api/Login/PatientLogin',
    viewAnalysisCount: '/api/Diagnosis/ViewAnalysisCount',
    viewAnalysisCountOpen: '/api/Diagnosis/ViewAnalysisCountOpen',
    ListPrakrutiAnalysis: '/api/Analysis/ListPrakrutiAnalysis',
    ListPrakrutiAnalysisOpen: '/api/Analysis/ListPrakrutiAnalysisOpen',
    ListGutAnalysis: '/api/Analysis/ListGutAnalysis',
    ListGutAnalysisOpen: '/api/Analysis/ListGutAnalysisOpen',
    updateGutAnalysis: '/api/Analysis/UpdateGutAnalysis',
    updatePrakrutiAnalysis: '/api/Analysis/UpdatePrakrutiAnalysis',
    bookAppointment: '/api/Patient/AppointmentBooking',
    scheduleAppointment: '/api/Patient/ListofDoctorAppointment',
    confirmationAppointment: '/api/Patient/AppointmentBookingRequest',
    followUpAppointment: '/api/Patient/FollowUpAppointments',
    SelfRegistrationMessage: '/api/Patient/SelfRegistrationMessage',
    AnalysisFormMessage: '/api/Patient/AnalysisFormMessage',
    SendConsentMessage: '/api/Patient/SendConsentMessage',
    GetOrganizationInfo: '/api/Organization/getOrganizationInfo',
    GetIpReports: '/api/Organization/getipreports',
    GetFacilityLogo:'/api/Organization/getfacilitylogo',
    GetDepartments: '/api/Organization/getHospitalDepartments',
    AddDepartmentsAndRooms: '/api/Organization/addDepartmentAndRooms',
    GetMasterReportData: '/api/UniversityDashboard/getmasterreport',
    GetPatientOverview: '/api/UniversityDashboard/getpatientoverview',
    GetHospitalList: '/api/UniversityDashboard/getorganizationbranches',
    GetAlertList: '/api/UniversityDashboard/getalertsdata',
    GetUserRoles: '/api/Organization/getRoles',
    GetRolePrivileges: '/api/Organization/getRolesPrivileges',
    AddStaff: '/api/Organization/AddStaff',
    SendEmailOtp: '/api/Communication/SendEmail',
    GetOrganizationBranches: '/api/Organization/getOrganizationBranches',
    AddOrganizationBranch: '/api/registration/AddOrganizationBranch',
    GetOrganizationBranchDepartments:
      '/api/Organization/getOrganizationBranchDepartment',
    admitPatient: '/api/Patient/AddPatientVisitCheckIn',
    addVitals: '/api/Analysis/AddVitals',
    getDiagnosisDetails: '/api/Diagnosis/GetDiagnosisData',
    addDoctorDiagnosis: '/api/Diagnosis/AddDoctorDiagnosis',
    getCarePlanAlerts: '/RequestConsultation/ViewInPatientCarePlanDetails',
    getUserOrganizationBranches:
      '/api/Organization/getUserOrganizationBranchInfo',
    getKriyas: '/api/Diagnosis/ViewProcedure',
    getMedications: '/api/Diagnosis/ViewMedication',

    // auth
    CheckAadhaar: '/api/auth/checkadhaar',
    GetUserCommunicationDetails: '/api/auth/getusercommunicationdetails',
    UpdateStaffVerificationStatus: '/api/auth/updatestaffstatus',
    UpdateUserPassword: '/api/auth/updateuserpassword',
    GetFeatureFlags: '/api/auth/getfeatureflags',

    //Organizations
    GetAllUserOrganizations: '/api/Organization/getallorganizationsofuser',
    CompleteBranchOnboarding: '/api/Organization/completebranchonboarding',
    CompleteBranchOnboardingAddStaff: '/api/Organization/addOnboardingStep',
    GetAllStaff: '/api/Organization/getallstaffs',
    GetBranchDepartmentStaffSchedule:
      '/api/Organization/getAllUsersInDeptOrgBranch',
    AddStaffDeptAndSchedule: '/api/Organization/AddStaffDeptAndSchedule',
    GetAllStaffNames: '/api/Organization/getallstaffsname',
    GetOrgBranchSchedule: '/api/Organization/GetOrganizationBranchSchedule',
    GetOrgBranchDepartment: '/api/Organization/getOrganizationBranchDepartment',
    GetDrugs: '/api/Prescription/ViewDrug',
    GetUserBranchRolesAndRights: '/api/Organization/getrolesandrights',
    ViewDoctors: '/api/Doctor/ViewDoctors',
    AddPatientDeptBranch: '/api/Patient/AddPatientDeptBranch',
    UpdateTotalStudents: '/api/organization/updateTotalStudents',

    DischargePatient: '/api/Patient/DischargePatient',
    GetPatientPermissionForOrg: '/api/Patient/GetPatientConsentForOrg',

    CreateRazorpaySubscription: '/api/Razorpay/addsubscription',
    AuthenticateRazorpayPayment: '/api/Razorpay/verifypayment',
    AddUserSubscriptionMapping: '/api/Razorpay/addusersubscriptionmapping',
    GetSubscriptionDetails: '/api/Razorpay/getsubscriptiondetails',
    CancelSubscription: '/api/Razorpay/cancelsubscription',
    ReassignPatient: '/api/Patient/ReassignPatient',
    AddAllergies: '/api/Patient/AddAllergies',
    AddLabImaging: '/api/Patient/AddLabImaging',
    AddLabTest: '/api/Patient/AddLabTest',
    UpdatePatientSymptom: '/api/Patient/UpdatePatientSymptom',
    ReferTaskToAnotherDoctor: '/api/Patient/ReferTaskToAnotherDoctor',
    GetReferralTasksForPatient: '/api/Patient/GetReferredTaskForPatient',
    AssignPatientTaskToDoctor: '/api/Patient/AssignPatientTaskToDoctor',
    UpdateMyTask: '/api/Patient/UpdateMyTask',
    CompleteMyTask: '/api/Patient/CompleteMyTask',
    AddLabTestForPatient: '/api/Patient/AddLabTestForPatient',
    AddImagingTestForPatient: '/api/Patient/AddImagingTestForPatient',
    getImage: '/api/Patient/getImage',

    UpdateOrganizationInfo: '/api/Organization/UpdateOrganizationInfo',
    getOrganizationBranchInfo: '/api/Organization/getOrganizationBranchInfo',
    UpdateOrganizationBranchInfo:
      '/api/Organization/UpdateOrganizationBranchInfo',
    UpdateBranchSchedule: '/api/Organization/UpdateBranchSchedule',
    UpdateUserDetails: '/api/Doctor/UpdateUserDetails',
    GetHospitalDepartmentRoomsAndBedsAsync:
      '/api/Organization/getHospitalDepartmentRoomsAndBedsAsync',
    EditDepartmentAndRooms: '/api/Organization/EditDepartmentAndRooms',
    UpdateDepartmentSchedule: '/api/Organization/UpdateDepartmentSchedule',
    UpdateStaffDeptSchedule: '/api/Organization/UpdateStaffDeptSchedule',
    EditStaff: '/api/Organization/EditStaff',
    ListIpdAndOpdConsultationForAllBranches:
      '/RequestConsultation/ListIpdAndOpdConsultationForAllBranches',
    ListOpdConsultationForAllBranches:
      '/RequestConsultation/ListAllOpdConsultationForAllBranches',
    GetUserDeptOrgBranchInfo: '/api/Organization/getUserDeptOrgBranchInfo',
    GetStaffSchedule: '/api/Organization/getstaffschedule',
    GetMyReferralTasksPatient: '/api/Patient/GetMyReferralTasksPatient',
    GetAllPatientPendingTest: '/api/Patient/GetPatientsPendingTest',

    CompleteImagingTest: '/api/Patient/CompleteImagingTest',

    CompleteLabTest: '/api/Patient/CompleteLabTest',
    UpdateTestResult: '/api/Patient/UpdateTestResult',
    // UpdateLabTestResult: '/api/Patient/UpdateLabTestResult',
    UpdatAssignTestToPractitionereLabTest: '/api/Patient/AssignTestToPractitioner',
    // UpdateImagingTest: '/api/Patient/AssignTestToPractitioner',
    GetFile: '/api/Patient/GetFile',
    UploadFacilityRatecard: '/api/Patient/UploadFacilityChargeItem',
    ExportProcedures: '/api/Patient/ExportChargeItemDefinition',
    GetFacilityRateCard: '/api/Patient/GetFacilityRateCard',
    UpdateFacilityRateCard: '/api/Patient/UpdateFacilityRateCard',
    DeleteFacilityRateCard: '/api/Patient/DeleteFacilityRateCard',
    GetPatientPrescriptionTest: '/api/Patient/GetPatientTests',
    Adddiagnosisdata : '/api/Diagnosis/AddDiagnosisData',
    GetPatientServiceInvoice: '/api/Patient/GetPatientServiceInvoice',
    GetAllPatientServiceInvoice: '/api/Patient/GetAllPatientServiceInvoice',
    ViewLabTechs:  '/api/Doctor/ViewLabTechs',
    settlependingamount : '/api/Patient/settlependingamount',
    UpdateDiagnosisFlag : '/RequestConsultation/UpdateDiagnosisFlag',
    Downloadabhaorqr  : '/api/Patient/downloadabhaorqr',
    Getinsurancelist : '/api/Patient/getinsurancelist',
    Gettpalist : '/api/Patient/gettpalist',
    Getpatientinsurance : '/api/Patient/getpatientinsurance',
    Addinsurancemapping : '/api/Patient/addinsurancemapping',
    GetInsuraceFile: '/api/Patient/GetInsuraceFile',
    Updatecheckinflag: '/api/Organization/updatecheckinflag',
    UpdateConsultationStatusProgress : '/RequestConsultation/UpdateConsultationStatusProgress',
    GetUserDetails : '/api/Doctor/GetUserDetails',
    GetCountries : '/api/registration/GetCountries',
    GetStates : '/api/registration/GetStates',
    GetDoctorSpecialities : '/api/registration/GetDoctorSpecialities',
    GetDoctorDegrees : '/api/registration/GetDoctorDegrees',
    ConsentForPatientOrg : '/api/Patient/ConsentForPatientOrg',
    GetPatientDetailsById : '/api/Patient/GetPatientDetailsById',
    SelectBranch : '/api/Organization/SelectBranch',
    Searchfacility : '/api/Organization/searchfacility',

  },
};
