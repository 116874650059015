import { Routes } from '@angular/router';
import { AuthGuard } from './core/gaurds/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { p1PatientPostRegistrationComponent } from './modules/p1-patient/components/p1patient-post-registration/p1patient-post-registration.component';
import { ThankYouPageComponent } from './modules/p1-patient/components/thank-you-page/thank-you-page.component';
// import { InstOnboardingComponent } from './modules/inst-onboarding/inst-onboarding.component';
// import { UniGuard } from './core/gaurds/university.guard';
// import { MasterReportComponent } from './modules/dashboard/components/unidashboard/master-report/master-report.component';
// import { PatientOverviewComponent } from './modules/dashboard/components/unidashboard/patient-overview/patient-overview.component';
// import { PerformanceReportComponent } from './modules/dashboard/components/unidashboard/performance-report/performance-report.component';
// import { UnidashboardComponent } from './modules/dashboard/components/unidashboard/unidashboard.component';
import { StaffGuard } from './core/gaurds/staff/staff.guard';
import { RazorpaySubscriptionGuard } from './core/gaurds/razorpaysubscription.guard';
import { SettingGuard } from './core/gaurds/routes-guard/setting.guard';
import { AppointmentGuard } from './core/gaurds/routes-guard/appointment.guard';
import { SelfRegistrationGuard } from './core/gaurds/routes-guard/self-registration.guard';
import { UtilityService } from './core/services/utility.service';
import { AccessTokenGuard } from './core/gaurds/accessToken.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
   

      {
        path: 'doctor/profile-setting',
        loadChildren: () =>
          import('./modules/doctor-profile-setting/doctor-profile-setting..module').then(
            (obj) => obj.DoctorProfileSettingModule
          ),
       
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (obj) => obj.DashboardModule
          ),
          canActivate: [RazorpaySubscriptionGuard,AccessTokenGuard,AuthGuard]
      },
      {
        path: 'patient',
        loadChildren: () =>
          import('./modules/patient/patient.module').then(
            (obj) => obj.PatientModule
          ),
          canActivate: [RazorpaySubscriptionGuard]
      },
      {
        path: 'appointment',
        loadChildren: () =>
          import('./modules/appointment/appointment.module').then(
            (obj) => obj.AppointmentModule
          ),
          canActivate: [RazorpaySubscriptionGuard,AppointmentGuard]
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('./modules/campaign/campaign.module').then(
            (obj) => obj.CampaignModule
          ),
          canActivate: [RazorpaySubscriptionGuard]
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./modules/setting/setting.module').then(
            (obj) => obj.SettingModule
          ),
          canActivate: [RazorpaySubscriptionGuard,SettingGuard]
      },

      {
        path: 'self-registration',
        loadChildren: () =>
          import('./modules/p1-patient/p1patient.module').then(
            (obj) => obj.p1PatientModule
          ),
          canActivate: [RazorpaySubscriptionGuard,SelfRegistrationGuard]
      },
      // { path: 'access-denied', component: AccessDeniedComponent },
    ],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
    canActivate: [AuthGuard, StaffGuard],
  },

  // {
  //   path: '',
  //   component: UnidashboardComponent,
  //   // canActivate: [UniGuard],
  //   children: [
  //     {
  //       path: 'unidashboard/masterreport',
  //       component: MasterReportComponent,
  //       data: { title: 'Master Report' },
  //     },
  //     {
  //       path: 'unidashboard/patientoverview',
  //       component: PatientOverviewComponent,
  //       data: { title: 'Patient Overview' },
  //     },
  //     {
  //       path: 'unidashboard/performancereport',
  //       component: PerformanceReportComponent,
  //       data: { title: 'Performance Report' },
  //     },
  //   ],
  // },
  // {
  //   path: 'doctor-search',
  //   loadChildren: () =>
  //     import('./modules/doctor-search/doctor-search-routing').then(
  //       (m) => m.doctorSearchRoutes
  //     ),
  // },
  // {
  //   path: 'website',
  //   loadChildren: () =>
  //     import('./modules/webpage/webpage-routing').then(
  //       (obj) => obj.webpageRoutes
  //     ),
  // },
  // {
  //   path: ':name',
  //   component: WebpageComponent,
  // },
  {
    path: 'patien-consent',
    component: p1PatientPostRegistrationComponent,
    data: { title: 'Consent' },
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-thank-you',
    component: ThankYouPageComponent,
    data: { title: 'Thank you' },
    pathMatch: 'full',
  },
  // {
  //   path: 'hospital/onboarding',
  //   component: OrgOnboardingComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     title: 'Hospital Onboarding',
  //     urls: [{ title: 'Hospital Onboarding' }],
  //   },
  // },
  // {
  //   path: 'institute/onboarding',
  //   component: HospitalSelectComponent,
  //   data: {
  //     title: 'Institute Onboarding',
  //     urls: [{ title: 'Institute Onboarding' }],
  //   },
  // },
  {
    path: 'unidashboard',
    loadChildren: () =>
      import(
        '../app/modules/dashboard/components/unidashboard/unidashboard-routing'
      ).then((m) => m.UnidashboardRoutingModule),
  },
  // {
  //   path: 'institute/hospital-onboarding',
  //   component: InstOnboardingComponent,
  //   data: {
  //     title: 'Institute Hospital Info',
  //     urls: [{ title: 'Hospital Info' }],
  //   },
  // },
];
